import PropTypes from 'prop-types'
import React from 'react'
import profilePic from '../images/profilePic.jpg'

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    {/*     <div className="logo">
      <span className="icon fa-diamond"></span>
    </div> */}
    <img className="logo" src={profilePic} alt={'Mark Ford Realtor'} />
    <div className="content">
      <div className="inner">
        <h1>Mark Ford Peak Realty Ltd. Brokerage</h1>
        <p>
          Buying or selling, I am here to assist you on your real estate
          adventure.
          <br />
          Located in Paris Ontario, I am readily available in Paris, Brant
          County, and surrounding areas.
        </p>
        <span className="headerContactInfo">
          <a className="headerLink" href="tel:5197612183">
            Direct: 519.761.2183
          </a>
          <a className="headerLink" href="tel:5194423100">
            {' '}
            Office: 519.442.3100
          </a>
          <a className="headerLink" href="emailto:mford570@gmail.com">
            mford570@gmail.com
          </a>
        </span>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('intro')
            }}
          >
            Buying
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('work')
            }}
          >
            Selling
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('marketUpdate')
            }}
          >
            Market Update
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('about')
            }}
          >
            About
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            Contact
          </button>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
